<div class="container" mat-dialog-content>
  <h3>Kopiera prislista</h3>

  <form (ngSubmit)="onCopyClick()" *ngIf="copyPricelistForm" [formGroup]="copyPricelistForm">
    <mat-form-field>
      <mat-label>Namn</mat-label>

      <input matInput formControlName="name" name="name" spellcheck="false" autocomplete="off">
    </mat-form-field>

    <mat-form-field appearance="fill" class="copy-dropdown">
      <mat-label>Välj prislista att kopiera</mat-label>
      <mat-select formControlName="selectedPricelist" name="selectedPricelist">
        <mat-option *ngFor="let pricelist of currentPriceLists$ | async" [value]="pricelist">
          {{ pricelist.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="buttons">
      <button type="button" mat-button (click)="onCancelClick()">Avbryt</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="copyPricelistForm.invalid">Skapa</button>
    </div>
  </form>
</div>