import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { OrderDTO, PaymentDTO } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { Refund } from 'src/app/core/states/orders/orders.action';

@Component({
  selector: 'app-manual-refund',
  templateUrl: './manual-refund.component.html',
  styleUrls: ['./manual-refund.component.scss'],
})
export class ManualRefundComponent {
  refundAmt = new FormControl<number | undefined>(this.data.maxRefund, [
    Validators.required,
    Validators.max(this.data.maxRefund),
    Validators.min(1),
  ]);
  refundCmt = new FormControl<string | undefined>(
    undefined,
    Validators.required
  );
  private onDestroy$ = new Subject<void>();

  constructor(
    private dialog: MatDialogRef<ManualRefundComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      order: OrderDTO;
      refund: PaymentDTO;
      maxRefund: number;
      orderItemId: number;
    },
    private store: Store
  ) {}

  refund() {
    if (
      this.refundAmt.valid &&
      this.refundCmt.valid && this.refundCmt.value?.trim()
    ) {
      const payment: PaymentDTO = {
        paymentMethod: {paymentMethodId: 'BANK'},
        transactionType: 'REFUND',
        amount: this.refundAmt.value!,
        comment: this.refundCmt.value!,
      };
      this.store
        .dispatch(
          new Refund(
            this.data.order.orderId!,
            this.data.orderItemId,
            this.data.refund.paymentId!,
            payment
          )
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.dialog.close({ success: true });
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
