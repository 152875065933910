<div class="sms-page-wrapper">
    <h2>SMS-inställningar</h2>
    <p class="info">Inställningar för inledande hälsning samt avslutande meddelande i bekräftelse- eller
        hemkomst-SMS.</p>
    <p class="info">Till höger
        syns en förhandsgransking av SMS:et tillsammans med en uppskattning av hur många SMS som kommer skickas. Tänk på
        att hålla dig kort och koncis för att hålla SMS-antalet och kostnaderna låga.
    </p>

    <!-- Help icon -->
    <div class="info-icon">
        <mat-icon>help</mat-icon>
        <div class="show-on-info-icon-hover">
            <h4>SMS-antal</h4>
            <ul>
                <li>
                    Antal tecken är ungefärliga och kan
                    komma att ändras i det SMS som skickas till kund beroende på längd på namn, e-post m.m.
                </li>
                <li>Var medveten om att specialtecken kan räknas som flera antal tecken i SMS:et</li>
                <li>
                    Antal SMS är uppskattade utifrån
                    153 tecken/SMS
                </li>
            </ul>
        </div>
    </div>

    <mat-tab-group mat-align-tabs="center" dynamicHeight="true" color="primary"
        (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Bekräftelse">
            <div class="sms-settings-wrapper">
                <section class="form-wrapper">
                    <h3>Ändra bekräftelsemeddelande</h3>

                    <form class="sms-confirm-form" spellcheck="false">
                        <h4>Inledande hälsning</h4>

                        <mat-form-field class="sms-confirm-input">
                            <mat-label>Hälsningsfras</mat-label>
                            <input matInput [formControl]="preText" maxlength="80" />
                            <mat-hint>{{preText.value.length}} tecken</mat-hint>
                        </mat-form-field>

                        <div class="sms-confirm-buttons">
                            <button mat-flat-button (click)="reset('PRE')" *ngIf="preTextChanged">Avbryt</button>
                            <button mat-raised-button color="primary" (click)="save('PRE', 'CONFIRMATION')"
                                [disabled]="!preTextChanged">Spara</button>
                        </div>
                    </form>

                    <form class="sms-confirm-form" spellcheck="false">
                        <h4>Avslutande meddelande</h4>

                        <mat-form-field class="sms-confirm-textarea">
                            <mat-label>Meddelande</mat-label>
                            <textarea matInput cols="50" rows="5" maxlength="160" [formControl]="postText"></textarea>
                            <mat-hint>{{postText.value.length}} tecken</mat-hint>
                        </mat-form-field>

                        <div class="sms-confirm-buttons">
                            <button mat-flat-button (click)="reset('POST')" *ngIf="postTextChanged">Avbryt</button>
                            <button mat-raised-button color="primary" (click)="save('POST', 'CONFIRMATION')"
                                [disabled]="!postTextChanged">Spara</button>
                        </div>
                    </form>
                </section>

                <aside class="sms-preview  {{errorPreview ? 'error' : ''}}">
                    <p *ngIf="errorPreview" class="sms-preview-message">Det gick inte att hämta förhandsgranskingen just
                        nu</p>
                    <p *ngIf="!errorPreview" class="sms-preview-message" [innerHTML]="message"></p>
                    <span>
                        Antal tecken: {{message.length}} |
                        Antal SMS: {{countSMS()}}
                    </span>
                </aside>
            </div>
        </mat-tab>

        <mat-tab label="Hemkomst">
            <div class="sms-settings-wrapper">
                <section class="form-wrapper">
                    <h3>Ändra ankomstmeddelande</h3>

                    <form class="sms-confirm-form" spellcheck="false">
                        <h4>Inledande hälsning</h4>

                        <mat-form-field class="sms-confirm-input">
                            <mat-label>Hälsningsfras</mat-label>
                            <input matInput [formControl]="preText" maxlength="80" />
                            <mat-hint>{{preText.value.length}} tecken</mat-hint>
                        </mat-form-field>

                        <div class="sms-confirm-buttons">
                            <button mat-flat-button (click)="reset('PRE')" *ngIf="preTextChanged">Avbryt</button>
                            <button mat-raised-button color="primary" (click)="save('PRE', 'ARRIVAL')"
                                [disabled]="!preTextChanged">Spara</button>
                        </div>
                    </form>

                    <form class="sms-confirm-form" spellcheck="false">
                        <h4>Avslutande meddelande</h4>

                        <mat-form-field class="sms-confirm-textarea">
                            <mat-label>Meddelande</mat-label>
                            <textarea matInput cols="50" rows="5" maxlength="160" [formControl]="postText"></textarea>
                            <mat-hint>{{postText.value.length}} tecken</mat-hint>
                        </mat-form-field>

                        <div class="sms-confirm-buttons">
                            <button mat-flat-button (click)="reset('POST')" *ngIf="postTextChanged">Avbryt</button>
                            <button mat-raised-button color="primary" (click)="save('POST', 'ARRIVAL')"
                                [disabled]="!postTextChanged">Spara</button>
                        </div>
                    </form>
                </section>

                <aside class="sms-preview {{errorPreview ? 'error' : ''}}">
                    <p *ngIf="errorPreview" class="sms-preview-message">
                        Det gick inte att hämta förhandsgranskingen just nu</p>
                    <p *ngIf="!errorPreview" class="sms-preview-message" [innerHTML]="message"></p>
                    <span>
                        Antal tecken: {{message.length}} |
                        Antal SMS: {{countSMS()}}
                    </span>
                </aside>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>