<mat-toolbar color="accent">
  <!-- Parking -->
  <button [routerLink]="['/antal-p-platser']" routerLinkActive="active-link" (click)="partnerIsActiveNow = false">Antal
    P-platser</button>

    <!-- Reports -->
  <button [matMenuTriggerFor]="reportMenu" #reportMenuTrigger="matMenuTrigger" (click)="partnerIsActiveNow = false"
    class="{{rlReport.isActive ? 'active-link' : reportMenuTrigger.menuOpen ? 'sub-admin-menu-open' : ''}}">
    Rapporter
  </button>

  <mat-menu #reportMenu="matMenu" class="sub-admin-menu" (click)="partnerIsActiveNow = false">
    <button routerLinkActive #rlReport="routerLinkActive" [routerLink]="['/betalningsrapport']">
      Betalningsrapport
    </button>
    <button routerLinkActive #rlReport="routerLinkActive" [routerLink]="['/belaggningsrapport']">
      Beläggningsrapport
    </button>
    <button routerLinkActive #rlReport="routerLinkActive" [routerLink]="['/forskottsbetalningar']">
      Förskottsbetalningar
    </button>
    <button routerLinkActive #rlReport="routerLinkActive" [routerLink]="['/dagsrapport-artiklar']">
      Dagsrapport - artiklar
    </button>
    <button routerLinkActive #rlReport="routerLinkActive" [routerLink]="['/dagsrapport-betalmetod']">
      Dagsrapport - betalmetod
    </button>
    <button routerLinkActive #rlReport="routerLinkActive" [routerLink]="['/dagsavslut']">
      Dagsavslut
    </button>
  </mat-menu>

  <!-- Users -->
  <button [routerLink]="['/anvandare']" routerLinkActive="active-link"
    (click)="partnerIsActiveNow = false">Användare</button>

    <!-- Settings -->
  <button [matMenuTriggerFor]="settingsMenu" #settingsMenuTrigger="matMenuTrigger"
    class="{{rlSettings.isActive ? 'active-link' : settingsMenuTrigger.menuOpen ? 'sub-admin-menu-open' : ''}}"
    (click)="partnerIsActiveNow = false">
    Inställningar
  </button>

  <mat-menu #settingsMenu="matMenu" class="sub-admin-menu">
    <button [routerLink]="['/sms-installningar']" routerLinkActive #rlSettings="routerLinkActive">
      SMS
    </button>
    <!-- <button routerLinkActive #rlSettings="routerLinkActive" >Inställning 2</button> -->
  </mat-menu>

  <!-- Partners -->
  <button [matMenuTriggerFor]="partnersMenu" #partnersMenuTrigger="matMenuTrigger"
    class="{{partnerIsActiveNow ? 'active-link' : partnersMenuTrigger.menuOpen ? 'sub-admin-menu-open' : ''}}">
    Partnerportalen
  </button>

  <mat-menu #partnersMenu="matMenu" class="sub-admin-menu">
    <button *ngFor="let partner of partners" (click)="setCurrentPartner(partner); partnerIsActiveNow = true">
      {{partner.name}}
    </button>
  </mat-menu>
</mat-toolbar>