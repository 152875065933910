import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DefaultService, RoleDTO, UserDTO } from 'parking-sdk';
import * as Actions from './user.actions';
import { tap } from 'rxjs';

export interface UsersStateModel {
  users: UserDTO[];
  loggedInUser?: UserDTO;
  roles: RoleDTO[];
}

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    users: [],
    loggedInUser: undefined,
    roles: [],
  },
})
@Injectable()
export class UsersState {
  constructor(private defaultService: DefaultService) {}

  @Selector()
  static loggedInUser(state: UsersStateModel) {
    return state.loggedInUser;
  }

  @Selector()
  static currentUsers(state: UsersStateModel) {
    return state.users;
  }

  @Selector()
  static currentRoles(state: UsersStateModel) {
    return state.roles;
  }

  @Action(Actions.GetUsers)
  getUsers(context: StateContext<UsersStateModel>) {
    return this.defaultService.getUsers().pipe(
      tap({
        next: (users) => {
          context.patchState({ users: users.content });
        },
        error: (error) => console.error('Error fetching users: ', error),
      })
    );
  }

  @Action(Actions.SetLoggedInUser)
  setLoggedInUser(context: StateContext<UsersStateModel>) {
    return this.defaultService.getMe().pipe(
      tap({
        next: (user) => {
          context.patchState({ loggedInUser: user });
        },
        error: (error) =>
          console.error('Error fetching logged in user: ', error),
      })
    );
  }

  @Action(Actions.GetRoles)
  getRoles(context: StateContext<UsersStateModel>) {
    return this.defaultService.getRoles().pipe(
      tap({
        next: (roles) => {
          context.patchState({ roles: roles });
        },
        error: (error) => console.error('Error fetching roles: ', error),
      })
    );
  }

  @Action(Actions.AddUser)
  addUser(context: StateContext<UsersStateModel>, action: Actions.AddUser) {
    const { user } = action;

    return this.defaultService.createUser(user).pipe(
      tap({
        next: (newUser) => {
          context.patchState({
            users: [...context.getState().users, newUser],
          });
        },
        error: (error) => console.error('Error adding user: ', error),
      })
    );
  }

  @Action(Actions.RemoveUser)
  removeUser(
    context: StateContext<UsersStateModel>,
    action: Actions.RemoveUser
  ) {
    const { userId } = action;

    return this.defaultService.deleteUser(userId).pipe(
      tap({
        next: () => {
          context.patchState({
            users: context
              .getState()
              .users.filter((user) => user.id !== userId),
          });
        },
        error: (error) => console.error('Error deleting user: ', error),
      })
    );
  }

  @Action(Actions.UpdateUser)
  updateUser(
    context: StateContext<UsersStateModel>,
    action: Actions.UpdateUser
  ) {
    const { userId, updatedUser } = action;

    return this.defaultService.updateUser(userId, updatedUser).pipe(
      tap({
        next: () => {
          const currentUsers = context.getState().users;
          const updatedUsers = currentUsers.map((user) =>
            user.id === userId ? { ...user, ...updatedUser } : user
          );

          context.patchState({
            users: updatedUsers,
          });
        },
        error: (error) => console.error('Error updating user: ', error),
      })
    );
  }

  @Action(Actions.ResetUsersState)
  resetState(context: StateContext<UsersStateModel>) {
    context.setState({
      users: [],
      loggedInUser: undefined,
      roles: [],
    });
  }
}
